<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle"/>

    <transition name="layout-sidebar" style="width:208px;">
      <div style="background: #173649;" v-show="isSidebarVisible()" :class="sidebarClass" @click="onSidebarClick">
        <div class="layout-logo" style="background: #112C3D;line-height: 70px;margin-top: 0px;">
          <router-link to="/dashboard">
            <div class="logo">
              <img src="../../assets/images/realPageLogo.png"/>
              <label>智慧菌草云平台</label>
            </div>
          </router-link>
        </div>
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick"/>
      </div>
    </transition>

    <div class="layout-main" id="appContent" style="position: relative">
      <router-view/>
    </div>
  </div>

  <!-- 全局对象，请勿删除 -->
  <ConfirmDialog></ConfirmDialog>

  <!----chat----- -->
  <ChatWindow v-if="chatDisplay" ref="chat" @close="chatDisplay=false"></ChatWindow>
</template>

<script>
import AppTopBar from './AppTopbar';
import AppMenu from './AppMenu';
import ChatWindow from '@/views/modules/chat/chatWindow';
import PubSub from 'pubsub-js';
import {mapGetters} from 'vuex';

export default {
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    ChatWindow,
    // Badge,
  },
  data() {
    return {
      layoutMode: 'static',
      layoutColorMode: 'dark',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [],
      chatDisplay: false,
      messageList: [],
      iconDisplay: false,
      projectId: null,
      userinfo: null,
    };
  },
  mounted() {
    this.userinfo = JSON.parse(localStorage.getItem('userinfo'));
    // if (this.$route.path.indexOf('/data/detail/dtzz') > -1) {
    //   this.projectId = this.$route.query.projId;
    //   this.$axios.get('/proj_user_ref/getProjectUsers', {
    //     params: {
    //       id: this.projectId,
    //     },
    //   }).then((res) => {
    //     res.data.forEach((item) => {
    //       if (item.userId === this.userinfo.user.id) {
    //         this.iconDisplay = true;
    //       }
    //     });
    //   });
    // } else {
    //   this.iconDisplay = false;
    //   this.projectId = null;
    // }
    if (this.$route.path.indexOf('/data/detail/dtzz') > -1) {
      this.projectId = this.$route.query.projId;
    }
    this.menu = JSON.parse(localStorage.getItem('menuList'));
    this.createWebSocket();
  },
  watch: {
    '$route'() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
      if (this.$route.path.indexOf('/data/detail/dtzz') > -1) {
        this.projectId = this.$route.query.projId;
        this.$axios.get('/proj_user_ref/getProjectUsers', {
          params: {
            id: this.projectId,
          },
        }).then((res) => {
          res.data.forEach((item) => {
            if (item.userId === this.userinfo.user.id) {
              this.iconDisplay = true;
            }
          });
        });
      } else {
        this.iconDisplay = false;
        this.projectId = null;
      }
    },
    updata: {
      handler(data) {
        this.chatDisplay = data;
        if (this.chatDisplay) {
          this.$nextTick(() => {
            if (this.projectId) {
              this.$refs.chat.init(this.projectId);
            }
          });
        }
      },
    },
    changedata: {
      handler(data) {
        this.chatDisplay = data;
      },
    },
  },
  computed: {
    updata() {
      return this.$vuexData.state.chatDisplay;
    },
    changedata() {
      return this.$vuexData.state.chatDisplay;
    },
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive':
              this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active':
              this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$appState.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
        },
      ];
    },
    sidebarClass() {
      return [
        'layout-sidebar',
        {
          'layout-sidebar-dark': this.layoutColorMode === 'dark',
          'layout-sidebar-light': this.layoutColorMode === 'light',
        },
      ];
    },
    logo() {
      return this.layoutColorMode === 'dark'
          ? 'assets/layout/images/logo-white.svg'
          : 'assets/layout/images/logo.svg';
    },
  },
  methods: {
    reload() {
      if (window['chatWebSocket']) {
        console.log('重新连接服务器成功！');
        return;
      } else {
        window['chatWebSocket'] = new WebSocket(
            this.$config.webSocket_url + 'chat/' + user.id,
        );
        return this.reload();
      }
    },
    createWebSocket(e) {
      const _this = this;

      const user = JSON.parse(localStorage.getItem('userinfo')).user;
      window['chatWebSocket'] = new WebSocket(
          this.$config.webSocket_url + 'chat/' + user.id,
      );
      window['chatWebSocket'].onopen = function() {
        console.log(user.name + '连接服务器成功！');
      };
      window['chatWebSocket'].onclose = function() {
        if (localStorage.getItem('userinfo')) {

          _this.reload();
        } else {
          console.log(user.name + '与服务器断开连接！');
          console.log(
              'websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean,
          );
        }
      };
      window['chatWebSocket'].onmessage = function(message) {
        const data = JSON.parse(message.data);
        // console.log(data);
        if (data.type === 'newMessage') {
          _this.iconDisplay = true;
          _this.messageList = [];
          _this.messageList.push(data);
          // console.log(_this.messageList);
          PubSub.publish('pubsubData', _this);
          if (_this.$refs.chat) {
            _this.$nextTick(() => {
              let projectId = _this.$refs.chat.getProjectId();
              if (projectId == data.projectId) {
                console.log('改变聊天记录');
                _this.$refs.chat.updateChatHistory(data);
              }
            });
          }
        } else {
          if (_this.$refs.chat) {
            _this.$nextTick(() => {
              _this.$refs.chat.updateChatHistory(data);
            });
          }
        }
      };
    },
    // removeJump() {
    //   document.getElementById("icon").classList.remove("jump");
    // },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList) {
        element.classList.remove(className);
      } else {
        element.className = element.className.replace(
            new RegExp(
                '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
                'gi',
            ),
            ' ',
        );
      }
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static') return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay') return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
  },

  beforeUpdate() {
    // this.chatDisplay = this.$vuexData.state.chatDisplay;
    // console.log(this.chatDisplay);
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else this.removeClass(document.body, 'body-overflow-hidden');
  },
};
</script>

<style lang="scss">
#appContent::-webkit-scrollbar {
  display: none;
}

.p-toast.p-toast-topright {
  z-index: 1000;
  top: 70px;
}

.logo {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo label {
  padding-left: 8px;
  font-weight: bold;
  font-size: 12px;
  color: #cbe0eb;
  vertical-align: middle;
}

.logo img {
  padding-right: 8px;
  width: 40%;
  border-right: #425f6f 1px solid;
}

.badge {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
}
::-webkit-scrollbar{
  display: none;
}
</style>
