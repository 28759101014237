<template>
  <Dialog :closable='false' v-model:visible="display" class="mdialog" content-class="contentClass" style="width: 60vw;height:calc(624px + 4.5rem)" @hide="$emit('close')" @show="stopProp()" modal="true">
    <template #header>
      <div class="p-d-flex p-justify-between" style="width: calc(100% - 40px);height:20px">
        <strong>{{ projectName ? projectName : null }}</strong>
        <div style="position:relative">
          <div class="type">
          <span :style="{borderBottom:currentType===0?'3px solid #ffffff':null}" @click="changeType(0)">消息列表</span>
          <span :style="{borderBottom:currentType===1?'3px solid #ffffff':null}" @click="changeType(1)">项目信息</span>
          <span :style="{borderBottom:currentType===2?'3px solid #ffffff':null}" @click="changeType(2)">专家建议</span>
          <span :style="{borderBottom:currentType===3?'3px solid #ffffff':null}" @click="changeType(3)">农事记录</span>
        </div>
        <button @click="closeDialog" style="position:absolute;top:-6px;right:-40px;font-size:28px;border:none;cursor:pointer;background:#12A25D;color:#fff">×</button>
        </div>
      </div>
    </template>
    <div id="chatContent" v-if="currentType===0">
      <div v-for="(item,index) in messageList" style="margin-top: 40px;">
        <div :style="{justifyContent:user.id===item.sender?'flex-end':'flex-start'}" style="display: flex;position: relative;top:-1.5rem">
          <div :class="{'nameAndTimeRight':user.id===item.sender,'nameAndTimeLeft':user.id!==item.sender}">
            <span v-if="user.id!==item.sender">{{ item.senderName }}{{ item.sender == projectInfo.project.expertId
                ? '(专家)'
                : null }}</span>
            {{ item.time ? (new Date(item.time).toLocaleTimeString().
              substring(0, new Date(item.time).toLocaleTimeString().lastIndexOf(':'))) : null }}
            <span v-if="user.id===item.sender">{{item.isRead===0?'未读':'已读'}}</span>
          </div>
          <div v-if="index===0" style="position: absolute;width: 100%;text-align: center;height: 20px;top: -40px">
            {{ new Date(messageList[index].time).toLocaleDateString() }}
          </div>
          <div style="position: absolute;width: 100%;text-align: center;height: 20px;top: -40px"
            v-if="index>0 && messageList[index+1] && new Date(item.time).toLocaleDateString()!=new Date(messageList[index+1].time).toLocaleDateString()">
            {{ new Date(messageList[index + 1].time).toLocaleDateString() }}
          </div>

          <span style="padding: 5px .5rem;border-radius: 5px;position: relative;line-height: 20px" :style="{backgroundColor:user.id===item.sender?'rgba(18, 162, 93, 1)':'whitesmoke',color:user.id===item.sender?'white':null,
          borderColor:user.id===item.sender?'rgba(18, 162, 93, 1)':null}">
            <span v-if="item.mediaType===0">{{ item.content }}</span>
            <my-image v-if="item.mediaType===1" style="height: 100px" :src="$minioUrl+item.content"></my-image>
          </span>
        </div>
      </div>
    </div>
    <template #footer v-if="currentType===0">
      <div class="p-d-flex p-justify-start p-flex-wrap" style="width: 100%;height: 120px">
        <div style="width: 100%;text-align: left">
          <i class="pi pi-image" @click="sendPhoto" style="cursor:pointer"></i>
        </div>
        <InputText autofocus style="width: 100%;border: none;height: 50px" v-model="content" @keydown.enter="sendMessage"></InputText>
        <div style="width: 100%;text-align: right">
          <Button @click="sendMessage">发送</Button>
        </div>
      </div>
    </template>

    <show-project v-if="currentType===1" :id="projectId" :show_bar="false"></show-project>
    <show-suggest v-if="currentType===2" :id="projectInfo.plan==null?null:projectInfo.plan.id" :show_bar="false"></show-suggest>
    <all-rec-record v-if="currentType===3" :projectId="projectId" style="height: 55vh;overflow: scroll"></all-rec-record>
  </Dialog>
</template>

<script>
import ShowProject from "@/views/modules/project/processing/showProject";
import ShowSuggest from "@/views/modules/plan/showSuggest";
import AllRecRecord from "@/views/modules/data/overview/allRecRecord";
import myImage from "@/views/modules/chat/myImage";
export default {
  name: "chatWindow",
  components: { AllRecRecord, ShowSuggest, ShowProject, myImage },
  data() {
    return {
      interval: null,
      display: false,
      messageList: [],
      user: null,
      projectId: null,
      content: null,
      projectInfo: null,
      projectName: null,
      receiverId: null,
      current: 1,
      currentType: 0,
    };
  },
  mounted() {
    if (localStorage.getItem("userinfo")) {
      this.user = JSON.parse(localStorage.getItem("userinfo")).user;
    }
  },
  methods: {
    closeDialog(){
      this.display=false
      if(this.$vuexData.state.chatDisplay){
        this.$vuexData.commit('updata')
      }
    },
    init(projectId) {
      localStorage.setItem("projectId", projectId);
      this.display = true;
      this.projectId = projectId;
      this.getProjectAllInfo().then(() => {
        const data = {
          type: "open",
          info: {
            projectId: projectId,
            senderId: this.user.id,
            //receiverId: userId ? userId : this.projectInfo.project.expertId,
          },
        };
        //this.receiverId = userId ? userId : this.projectInfo.project.expertId;
        console.log(window["chatWebSocket"])
        if (window["chatWebSocket"]) {
          window["chatWebSocket"].send(JSON.stringify(data));
          // this.getProjectAllInfo().then(() => {
          //   const data = {
          //     type: "read",
          //     info: {
          //       projectId: projectId,
          //       senderId: this.user.id,
          //       //receiverId: userId ? userId : this.projectInfo.project.expertId,
          //     },
          //   };
          //   //this.receiverId = userId ? userId : this.projectInfo.project.expertId;
          //   if (window["chatWebSocket"]) {
          //     window["chatWebSocket"].send(JSON.stringify(data));
          //   }
          //   setTimeout(() => {
          //     this.scrollToTop();
          //   }, 500);
          // });
        }
        setTimeout(() => {
          this.scrollToTop();
        }, 500);
      });
    },
    scrollToTop() {
      const _this = this;
      let chatContent = document.getElementById("chatContent");
      chatContent.addEventListener("scroll", function () {
        if (chatContent.scrollTop < 30) {
          _this.current = _this.current + 1;
          const data = {
            type: "history",
            projectId: _this.projectId,
            current: _this.current,
          };
          window["chatWebSocket"].send(JSON.stringify(data));
        }
      });
    },
    scrollToBottom() {
      let div = document.getElementById("chatContent");
      div.scrollTop = div.scrollHeight + 60;
    },
    getProjectAllInfo() {
      return new Promise((resolve) => {
        this.$axios
          .get("/project/getProjectAboutAll", {
            params: {
              id: this.projectId,
            },
          })
          .then((res) => {
            this.projectInfo = res.data;
            this.projectName = res.data.project.name;
            resolve();
          });
      });
    },

    getProjectId() {
      return this.projectId;
    },
    // read() {

    // },
    updateChatHistory(data) {
      if (data.type === "newMessage" || data.type === "openWindow") {
        // console.log(this.projectId);
        const data = {
          type: "read",
          info: {
            projectId: this.projectId,
            sender: this.user.id,
          },
        };
        this.content = null;
        window["chatWebSocket"].send(JSON.stringify(data));
      }
      // console.log(data);
      const list = data.chatHistory;
      if (this.projectId && data.projectId == this.projectId) {
        if (data.type === "callbackHistory") {
          if (list.length > 0) {
            this.messageList = list.concat(this.messageList);
            this.current = data.current;
            this.$nextTick(() => {
              this.scrollToBottom();
            });
          }
        } else {
          this.messageList = list;
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      }
    },
    sendMessage() {
      if (this.content) {
        const data = {
          type: "send",
          info: {
            projectId: this.projectId,
            sender: this.user.id,
            mediaType: 0,
            content: this.content,
          },
        };
        // console.log("sendMessage");
        // console.log(data);
        this.content = null;
        window["chatWebSocket"].send(JSON.stringify(data));
      }
    },
    sendPhoto() {
      const _this = this;
      const input = document.createElement("input");
      input.type = "file";
      input.onchange = function () {
        let file = this.files[0];
        let formData = new FormData();
        formData.append("file", file);
        _this.$axios
          .post("/minio/uploadFile", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            const data = {
              type: "send",
              info: {
                projectId: _this.projectId,
                sender: _this.user.id,
                mediaType: 1,
                content: res.data,
              },
            };
            window["chatWebSocket"].send(JSON.stringify(data));
          });
      };
      input.click();
    },
    changeType(type) {
      this.currentType = type;
      if (type === 0) {
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    },
    stopProp() {
      // console.log('aa');
      document.getElementById("chatContent").addEventListener(
        "scroll",
        function (event) {
          // console.log('bb');
          event.stopPropagation();
        },
        false
      );
    },
  },
};
</script>
<style>
.contentClass {
  padding: 0 !important;
  height: 100%;
}
</style>
<style scoped>
#chatContent {
  height: calc(432px + 2rem);
  overflow: scroll;
  padding: 32px 1.5rem 2rem 1.5rem;
  background: rgba(18, 162, 93, 0.04);
}

.type > span {
  padding-bottom: 10px;
  cursor: pointer;
  margin-right: 1rem;
}

.nameAndTimeLeft {
  position: absolute;
  width: 60%;
  height: 20px;
  left: 0;
  top: -20px;
  font-size: 12px;
}

.nameAndTimeRight {
  position: absolute;
  width: 100px;
  height: 20px;
  right: 0;
  top: -20px;
  text-align: right;
  font-size: 12px;
}
::v-deep(.p-dialog .p-dialog-content) {
  overflow-y: auto;
}
#chatContent::-webkit-scrollbar {
  display: none;
}
</style>